// baseurl is compared to location.href
// apiurl is the base URL to prepend, this can differ from base
// make sure apiurl ends with a /
// should be ordered by length of base descending
// FIXME: cleanup/rework, bit of a mess
export default [
  {
    apiurl: "https://api-vitesse-staging.kemari.dev/en/",
    baseurl: "https://api-vitesse-staging.kemari.dev/en/",
    pathsite: "vitesse-en",
  },
  // {
  //   apiurl: "https://api-vitesse-staging.kemari.dev/en/",
  //   baseurl: "https://vitesse-staging-2.kemari.dev/en/",
  //   pathsite: "vitesse-en",
  // },
  {
    apiurl: "https://api-vitesse-staging.kemari.dev/en/",
    baseurl: "https://web-staging.vitesse.nl/en/",
    pathsite: "vitesse-en",
  },
  {
    apiurl: "https://api-vitesse-staging.kemari.dev/",
    baseurl: "https://api-vitesse-staging.kemari.dev/",
    pathsite: "vitesse",
  },
  {
    apiurl: "https://api-vitesse-staging.kemari.dev/en/",
    baseurl: "https://vitesse-staging.kemari.dev/en/",
    pathsite: "vitesse-en",
  },
  // {
  //   apiurl: "https://api-vitesse-staging.kemari.dev/",
  //   baseurl: "https://vitesse-staging-2.kemari.dev/",
  //   pathsite: "vitesse",
  // },
  {
    apiurl: "https://api-vitesse-staging.kemari.dev/",
    baseurl: "https://web-staging.vitesse.nl/",
    pathsite: "vitesse",
  },
  {
    apiurl: "https://api-vitesse-staging.kemari.dev/",
    baseurl: "https://vitesse-staging.kemari.dev/",
    pathsite: "vitesse",
  },
  {
    apiurl: "http://localhost:8001/vitesse/en/",
    baseurl: "http://localhost:8001/vitesse/en/",
    pathsite: "vitesse-en",
  },
  {
    apiurl: "http://localhost:8001/vitesse/",
    baseurl: "http://localhost:8001/vitesse/",
    pathsite: "vitesse",
  },
  // Live/production site
  {
    apiurl: "https://api-prod.vitesse.nl/en/",
    baseurl: "https://web-test-kemari.vitesse.nl/en",
    pathsite: "vitesse-en",
  },
  {
    apiurl: "https://api-prod.vitesse.nl/",
    baseurl: "https://web-test-kemari.vitesse.nl/nl",
    pathsite: "vitesse",
  },
  {
    apiurl: "https://api-prod.vitesse.nl/",
    baseurl: "https://web-test-kemari.vitesse.nl",
    pathsite: "vitesse",
  },
  // Live/production backend
  {
    apiurl: "https://api-prod.vitesse.nl/en/",
    baseurl: "https://api-prod.vitesse.nl/en/",
    pathsite: "vitesse-en",
  },
  {
    apiurl: "http://localhost:8001/vitesse/en/",
    baseurl: "http://localhost:3000/en/",
    pathsite: "vitesse-en",
  },
  {
    apiurl: "https://api-prod.vitesse.nl/",
    baseurl: "https://api-prod.vitesse.nl/",
    pathsite: "vitesse",
  },
  {
    apiurl: "http://localhost:8001/vitesse/",
    baseurl: "http://localhost:3000/",
    pathsite: "vitesse",
  },
  {
    apiurl: "https://api-prod.vitesse.nl/en/",
    baseurl: "https://vitesse.nl/en/",
    pathsite: "vitesse-en",
  },
  {
    apiurl: "https://api-prod.vitesse.nl/",
    baseurl: "https://vitesse.nl",
    pathsite: "vitesse",
  },
];
