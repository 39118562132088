// Vitesse API
import * as cookie from "dompack/extra/cookie";
import * as whintegration from '@mod-system/js/wh/integration';
import vitesseSiteOrigins from './origins.es';

export function getJSONContentURL(url = '') {
  url = url == '' ? location.href : url;

  const origin = vitesseSiteOrigins.find(_ => url.startsWith(_.baseurl));
  if (!origin) {
    throw `No origin found for URL "${url}"`;
  }

  // setup URL parts
  const parts = ['sitecontent',origin.pathsite,url.substr(origin.baseurl.length),'content.json'];

  // remove duplicate slashes and slashes at start and end to make a clean URL
  const path = parts.join('/').split('/').filter(v => v !== '').join('/');

  // return full URL
  return `${origin.apiurl}${path}`;
}

function getLoginConfig()
{
  const origin = findOrigin(location.href);

  return { branded_ui_url: "https://login.staging.vitesse.nl/"
         , tenant: "HN8Lea7-KUicobBOc3hPXQ"
         , ssolanding: origin.apiurl + '.vitesse-siteapi/sso.shtml?why=landing&'
         };
}

function findOrigin(url = '') {
  const origin = vitesseSiteOrigins.find(_ => url.startsWith(_.baseurl));
  if (!origin) {
    throw `No origin found for URL "${url}"`;
  }
  return origin;
}

function login()
{
  let loginconfig = getLoginConfig();
  //ensure absolute URL
  loginconfig.ssolanding = new URL(loginconfig.ssolanding, location.href);
  // loginconfig.ssolanding.searchParams.set("back", location.href);

  const origin = findOrigin(location.href);
  const backURL = `${origin.baseurl}/mijn-vitesse`;
  loginconfig.ssolanding.searchParams.set("back", backURL);
console.log("back", backURL);

  //generate SSO login url
  let loginurl = new URL("/auth/login", loginconfig.branded_ui_url);
  loginurl.searchParams.set("tenantid", loginconfig.tenant);
  loginurl.searchParams.set("returnvisitorurl", location.href);
  loginurl.searchParams.set("successredirecturl", loginconfig.ssolanding.toString());

console.log(loginurl.toString());

  //This parameter enforces mandatory registration mode in which users must register a full account rather than a lite or social account. Not including this parameter results in lite registration by default and social sign in permitted.
  // loginurl.searchParams.set("mandatory", "true");

  //redirect user to login page
  location.href = loginurl.toString();
}

function logout()
{
  //NOTE DOESN'T WORK ... just sends you to the profile page
  let loginconfig = getLoginConfig();
  let logouturl = new URL("/auth/logout", loginconfig.branded_ui_url);
  logouturl.searchParams.set("tenantid", loginconfig.tenant);
  logouturl.searchParams.set("returnvisitorurl", location.href);
  logouturl.searchParams.set("forcelogout", "true");
  location.href = logouturl.toString();
}

function getLoginStatus()
{
  let logininfo = JSON.parse(cookie.read("vitesse_xs_info") || null);
  if(!logininfo || logininfo.exp < (Date.now() / 1000))
    return null; //expired!

  return { firstname: logininfo.fn
         };
}

function isLoggedIn() {
  const status = getLoginStatus();
  return !!status;
}

//"export" the API as a public object
window.vitesseSiteAPI =
{
  login
// , logout
, getLoginStatus
, isLoggedIn
, getJSONContentURL
, getLoginConfig
, origins: vitesseSiteOrigins
, executeSubmitInstruction: whintegration.executeSubmitInstruction
};
